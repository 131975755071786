.HomeSection {
    height: 100dvh;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: flex-end;
    text-align: center;
    overflow: hidden;
    position: relative;
    gap: 5rem;
}

.HomeSection_background,
.HomeSection_backgroundWide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
}

.HomeSection_background > rect,
.HomeSection_backgroundWide > rect {
    width: 100%;
    height: 100%;
}


.HomeSection_backgroundWide { 
    display: none;
}

.HomeSection_sceneContainer {
    position: absolute;
    height: 100%;
    min-width: 100%;
}

.HomeSection_main {
    display: grid;
    gap: 3rem;
    z-index: 2;
}

.HomeSection_button {
    padding: .5em 0;
    font-weight: bold;
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #00C6BD;
    border: 1px solid #FFFFFF30;
    background: linear-gradient(to left, #141E30 50%, #243B55 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    width: clamp(120px, 50vw, 400px);
    justify-self: center;
    border-radius: 5px;
    user-select: none;
}

.HomeSection_button:hover {
    cursor: pointer;
    background-position: left;
}

.HomeSection_scrollContainer {
    padding-bottom: 2rem;
    display: flex;
    place-content: center;
    z-index: 2;
}

.HomeSection_scrollButton {
    display: grid;
    row-gap: 0.5rem;
}

.HomeSection_scrollButton:hover svg,
.HomeSection_scrollButton:hover span {
    cursor: pointer;
    color: #00C6BD;
    fill: #00C6BD;
}

.HomeSection_scrollText {
    font-size: clamp(0.8rem, 3vw, 1rem);
    color: #E2F1FF;
    user-select: none;
}

.HomeSection_scrollTriangle {
    width: 1rem;
    height: auto;
    place-self: center;
    fill: #E2F1FF;
    stroke-width: 3;
}

@media screen and (min-width: 1400px) {
    .HomeSection_background {
        display: none
    }
    .HomeSection_backgroundWide {
        display: block;
    }
}

@media screen and (max-height: 600px) {
    .HomeSection {
        gap: 5rem;
    }
    .HomeSection_main {
        gap: 1rem;
    }
    .HomeSection_button {
        font-size: 1rem;
        width: 200px;
    }
}


