.Footer {
    background-color: #141E30;
    color: #E2F1FF;
    padding: 2rem;
    display: grid;
    gap: 1rem;
}

.Footer_iconContainer {
    display: flex;
    border-bottom: solid 1px #243B55;
    gap: 1rem;
    padding-bottom: 1rem;
}

.Footer_icon {
    width: 1.5rem;
    height: auto;
    color: #E2F1FF;
}

.Footer_icon:hover {
    cursor: pointer;
    color: #00C6BD;
}

.Footer_name {
    color: #E2F1FF;
    display: flex;
    font-size: 1rem;
}

.Footer_name:hover {
    color: #00C6BD;
}

.Footer_text {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}