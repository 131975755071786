.Scramble {
    display: flex;
    justify-content: center;
}

.Scramble_text {
    font-size: clamp(1.2rem, 5vw, 2.5rem);
    color: #E2F1FF;
    user-select: none;
}

@media screen and (max-height: 600px) {
    .Scramble_text {
        font-size: 2rem
    }
}




