body, button, input[type=submit] {
  font-family: 'Major Mono Display', monospace;
}

body, h1, h2, h3, p, button {
    margin: 0;
    padding: 0;
}

body {
  background-color: #243B55;
  color: #E2F1FF;
  z-index: -2;
}

button {
  background-color: transparent;
  border: none;
}

button:hover {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
