.ContactSection {
    display: grid;
    padding-top: 4rem;
    padding-bottom: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 1.5rem;
    overflow: hidden;
    position: relative;
}

.ContactSection_header {
    display: flex;
    margin-top: -2rem;
    margin-bottom: -5rem;
}

.ContactSection_headerWide {
    display: none; 
}

.ContactSection_text {
    font-size: clamp(1rem, 4vw, 1.5rem);
    font-family: 'Poppins', sans-serif;
}

.ContactSection_thanks {
    display: none;
    flex-direction: column;
    row-gap: 1rem;
    position: absolute;
    place-self: end center;
    height: 50%;
    font-size: clamp(1rem, 4vw, 1.5rem);
}

.ContactSection_smile {
    font-size: 4rem;
    color: #00C6BD;
}

.ContactSection_smile:hover {
    cursor: pointer;
    opacity: 0.7;
}

@media screen and (min-width: 1200px) {
    .ContactSection {
        padding-left: 25vw;
        padding-right: 25vw;
    }

    .ContactSection_headerWide {
        display: flex;
        margin-bottom: -3rem;
    }

    .ContactSection_header {
        display: none
    }
}

@media screen and (max-height: 600px) {
    .ContactSection {
        gap: 1rem
    }
    .ContactSection_text {
        font-size: 1rem;
    }
}