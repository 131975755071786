.Navbar {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

.Navbar_topRow {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0 1rem 0 5rem;
    border: solid 1px #FFFFFF30;
    background-color: #141e30;
}

.Navbar_header {
    color: #E2F1FF;
    font-size: clamp(1rem, 4vw, 1.5rem);
    display: inline;
}

.Navbar_header:hover {
    color:#00C6BD;
}

.Navbar_hamburgerContainer {
    position: absolute;
    margin: .5rem;
    z-index: 3;
}

.Navbar_hamburger {
    border: solid 1px #FFFFFF30;
    background-color: #141e3075;
    fill: #E2F1FF;
    width: 3rem;
    height: auto;
    border-radius: 5px;
}

.Navbar_buttonsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    height: calc(100dvh - 4rem);
    display: none;
}

.Navbar_button {
    color: #E2F1FF;
    background-color: #243B55;
    border: solid 1px #FFFFFF30;
    font-size: clamp(1rem, 8vw, 2rem);
}


.Navbar_button:hover { 
    color: #00C6BD;
}


.Navbar_button > span {
    color: #00C6BD;
}
