.AboutSection {
    display: grid;
    padding-top: 4rem;
    padding-bottom: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 2rem;
    overflow: hidden;
}

.AboutSection_header {
    display: flex;
    margin-top: -2rem;
    margin-bottom: -5rem;
}

.AboutSection_headerWide {
    display: none;
}

.AboutSection_textSection {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.AboutSection_skillsSection {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.AboutSection_projectsButton {
    color: #00C6BD;
}

.AboutSection_projectsButton:hover { 
    color: #141e30;
}


.AboutSection_projectsButton,
.AboutSection_text {
    font-size: clamp(1rem, 4vw, 1.5rem);
    font-family: 'Poppins', sans-serif;
}

.AboutSection_text > span {
    color: #00C6BD;
}

.AboutSection_bold {
    font-weight: bold;
}

.AboutSection_skillsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

.AboutSection_certsContainer {
    margin-top: 2rem;
}

.AboutSection_certImage:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.1s;
}

.AboutSection_skill {
    color: #E2F1FF;
    padding: .2rem .4rem;
    background-color: #141e30;
    font-size: clamp(1rem, 4vw, 1.5rem);
    font-family: 'Poppins', sans-serif;
    border-radius: .5rem;
}

.AboutSection_callToAction {
    display: grid;
    justify-items: left;
    gap: .5rem;
}

.AboutSection_button {
    padding: .5em;
    font-weight: bold;
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #00C6BD;
    background: linear-gradient(to left, #243B55 50%, #141e30 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    border: solid 1px #00C6BD;
    border-radius: 5px;
}

.AboutSection_button:hover {
    cursor: pointer;
    background-position: left;
}

@media screen and (min-width: 1200px) {
    .AboutSection {
        grid-template-columns: 1fr 1fr;
        padding-left: 10rem;
        padding-right: 10rem;
        gap: 4rem;
    }

    .AboutSection_headerWide {
        display: flex;
        margin-bottom: -3rem;
    }

    .AboutSection_header {
        display: none
    }

    .AboutSection_headerWide {
        grid-column: 1 / 3;
    }

    .AboutSection_skillsSection {
        grid-column: 2 / 3;
        grid-row: 2 / 4;
    }

}

@media screen and (max-height: 600px) {
    .AboutSection {
        padding: 2rem 5rem;
        gap: 1rem;
        column-gap: 3rem;
    }
}