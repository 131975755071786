.ProjectsSection {
    display: grid;
    padding-top: 4rem;
    padding-bottom: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 2rem;
    color: #E2F1FF;
    overflow: hidden;
    position: relative;
}

.ProjectsSection_background {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
}

.ProjectsSection_background > rect {
    width: 100%;
    height: 100%;
}

.ProjectsSection_header {
    display: flex;
    margin-bottom: -5rem;
    margin-top: -2rem;
}

.ProjectsSection_headerWide {
    display: none;
}

.ProjectsSection_header > span{
    color: #00C6BD;
}

.ProjectsSection_projectContainer {
    display: grid;
    gap: 1rem;
    max-width: 850px;
    margin: 0 auto;
}

.ProjectsSection_leftContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-x: hidden;
}

.ProjectsSection_skcWide {
    display: none;
}

.ProjectsSection_projectImageContainer {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
}

.ProjectsSection_projectImage {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.ProjectsSection_imageButtonsContainer {
    display: flex;
    justify-content: space-between;
    gap: 0.2rem;
}

.ProjectsSection_imageButton {
    color: #FFFFFF;
    border: solid 1px #FFFFFF30;
    font-size: 1rem;
    border-radius: 5px;
    padding: 0.4rem;
    text-align: center;
    place-content: center;
    place-items: center;
    user-select: none;
}

.ProjectsSection_imageButton:hover {
    background-color: #FFFFFF30;
}

.ProjectsSection_imageText {
    font-size: 0.8rem;
    user-select: none;
    align-self: center;
    text-align: center;
}

.ProjectsSection_imageOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #141E30;
    border-radius: 5px;
}

.ProjectsSection_descriptionContainer {
    display: grid;
    gap: 1rem;
}

.ProjectsSection_textSection {
    border: solid 1px #FFFFFF30;
    background:#FFFFFF10;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding: 1rem;
    display: grid;
    gap: 0.5rem;
}

.ProjectsSection_list > li {
    margin-bottom: 0.5rem;
}

.ProjectsSection_list > li:last-child {
    margin-bottom: 0;
}

.ProjectsSection_list {
    margin: 0;
    padding-left: 1.5rem;
    gap: 0.5rem;
    list-style: circle;
}

.ProjectsSection_projectName {
    font-weight: bold;
    font-size: clamp(1.2rem, 4vw, 1.8rem);
    border-bottom: solid 1px #FFFFFF30;
    padding-bottom: 0.5rem;
}

.ProjectsSection_textContainer {
    display: grid;
    gap: 0.5rem
}

.ProjectsSection_sectionLabel {
    font-weight: bolder;
    color: #00C6BD;
}

.ProjectsSection_freeTier {
    font-size: clamp(0.9rem, 3vw, 1rem);
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.ProjectsSection_text {
    font-size: clamp(1rem, 4vw, 1.5rem);
    font-family: 'Poppins', sans-serif;
}

.ProjectsSection_buttonContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.ProjectsSection_button {
    padding: .5em;
    font-weight: bold;
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #00C6BD;
    border: 1px solid #00C6BD;
    background: linear-gradient(to left, #141E30 50%, #243B55 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    justify-self: center;
    border-radius: 5px;
    text-decoration: none;
}

.ProjectsSection_button:hover {
    cursor: pointer;
    background-position: left;
}


@media screen and (min-width: 1200px) {
    .ProjectsSection {
        padding-left: 10rem;
        padding-right: 10rem;
        row-gap: 4rem;
    }

    .ProjectsSection_headerWide {
        display: flex;
        margin-bottom: -5rem;
    }

    .ProjectsSection_header {
        display: none
    }


    .ProjectsSection_projectContainer { 
        max-width: none;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }

    .ProjectsSection_skcWide {
        display: inherit;
    }

    .ProjectsSection_skc {
        display: none;
    }

    .ProjectsSection_descriptionContainer {
        margin-top: 2rem;
    }

    .ProjectsSection_freeTier {
        margin-top: -2rem;
    }

    .ProjectsSection_imageText {
        font-size: 1rem;
    }

    
    .ProjectsSection_imageButton {
        font-size: 1.5rem;
    }
}

@media screen and (max-height: 600px) {
    .ProjectsSection {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .ProjectsSection_projectContainer {
        gap: 2rem;
    }
}
